import React from 'react';

import { cn } from 'src/@utils/mergeClassName';

export const Footer = ({ className }: { className?: string }) => {
  return (
    <div className={cn("text-center font-['Aleo']", 'text-white', className)}>
      <a href="mailto:contact@primelabs.ai">
        <p>Email : contact@primelabs.ai</p>
      </a>
      <p>copyright © 2024 primelabs</p>
    </div>
  );
};
