import logo from '@assets/image/primelabs-logo.png';
import { cn } from 'src/@utils/mergeClassName';

import { Phrase } from './Phrase';
import { ExternalLinkButton } from './ExternalLinkButton';
import { Footer } from './Footer';

export const PC_Home = () => {
  return (
    <div className="h-screen min-h-[820px] bg-[#283B36] font-pretendard">
      <div className="mx-auto flex h-full w-[700px] items-center justify-center">
        <div className="">
          <div className="mx-auto mb-[76px] w-fit">
            <img
              src={logo}
              alt="logo"
              width={400}
              className="w-[20vw] min-w-[320px] max-w-[400px]"
            />
          </div>
          <Phrase
            className={cn(
              'mb-[95px]',
              'text-[48px] max-xl:text-[44px] max-lg:text-[40px] max-md:text-[32px]',
            )}
          />
          <div
            className={cn(
              'mb-[228px] flex w-full gap-x-10',
              'text-30 max-xl:text-[26px] max-lg:text-24 max-md:text-22',
            )}
          >
            <ExternalLinkButton
              to={'https://www.notion.so/dcb6ee81569841f3bf515cf0a9bdf9f3?pvs=4'}
              variants={'primary'}
            >
              채용공고
            </ExternalLinkButton>
            <ExternalLinkButton to={'https://tally.so/r/wQVNXp'} variants={'secondary'}>
              문의하기
            </ExternalLinkButton>
          </div>
          <Footer className="text-[24px] max-md:text-[20px]" />
        </div>
      </div>
    </div>
  );
};
