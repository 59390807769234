import logo from '@assets/image/primelabs-logo.png';
import { cn } from 'src/@utils/mergeClassName';

import { Phrase } from './Phrase';
import { ExternalLinkButton } from './ExternalLinkButton';
import { Footer } from './Footer';

export const M_Home = () => {
  return (
    <div className="h-screen bg-[#283B36] text-center font-pretendard">
      <div className="mx-auto flex h-full items-center justify-center">
        <div className="">
          <div className="mx-auto mb-[22px] w-fit">
            <img
              src={logo}
              alt="logo"
              width={200}
              className="w-[40vw] min-w-[200px] max-w-[300px]"
            />
          </div>
          <Phrase className={cn('mb-[180px]', 'text-[20px]')} />
          <div className={cn('mx-auto', 'mb-[72px] w-[200px] space-y-2', 'text-[15px]')}>
            <ExternalLinkButton
              to={'https://primelabs.career.greetinghr.com/'}
              variants={'primary'}
              className="py-2"
            >
              채용공고
            </ExternalLinkButton>
            <ExternalLinkButton
              to={'https://tally.so/r/wQVNXp'}
              variants={'secondary'}
              className="py-2"
            >
              문의하기
            </ExternalLinkButton>
          </div>
          <Footer className="text-[12px]" />
        </div>
      </div>
    </div>
  );
};
