import { FC } from 'react';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { RootRouter } from '@route';

const App: FC = () => {

  const router = createBrowserRouter(
    createRoutesFromElements(<Route path="/*" element={<RootRouter />} />),
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
