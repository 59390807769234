import React from 'react';

import { RecoilRoot } from 'recoil';

import ReactDOM from 'react-dom/client';
import './index.css';
import _ from 'lodash';
import ConfigProvider from 'antd/es/config-provider';
import locale from 'antd/locale/ko_KR';

import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
      <RecoilRoot>
        <ConfigProvider
          locale={locale}
          theme={{
            components: {
              Checkbox: {
                colorPrimary: '#EA3F49',
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </RecoilRoot>
  </React.StrictMode>,
);

if (process.env.REACT_APP_MODE === 'deploy') {
  console.log = _.noop;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
