import { cn } from 'src/@utils/mergeClassName';

export const Phrase = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'mx-auto w-fit',
        "whitespace-nowrap text-center font-['Aleo'] text-white",
        className,
      )}
    >
      <p>We innovate creator’s workflow,</p>
      <p>make AI applied products.</p>
    </div>
  );
};
