import { ReactNode } from 'react';

import { cn } from 'src/@utils/mergeClassName';

interface ExternalLinkButtonProps {
  to: string;
  children: ReactNode;
  variants: 'primary' | 'secondary';
  className?: string;
}

export const ExternalLinkButton = ({
  to,
  children,
  variants,
  className,
}: ExternalLinkButtonProps) => {
  const variant =
    variants === 'primary'
      ? /** @tw */ 'text-black border-white bg-white'
      : /** @tw */ 'border-white bg-transparent text-white';

  return (
    <a href={to} target="_blank" className="box-border block w-full">
      <button
        className={cn('w-full rounded-[20px] border-[5px] py-4', variant, className)}
      >
        {children}
      </button>
    </a>
  );
};
