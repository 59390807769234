import { PC_Home } from '@containers/Home/components/PC_Home';
import { M_Home } from '@containers/Home/components/M_Home';

export const Home = () => {
  return (
    <div>
      <div className="max-sm:hidden">
        <PC_Home />
      </div>
      <div className="hidden max-sm:block">
        <M_Home />
      </div>
    </div>
  );
};
