import { FC } from 'react';

import { Home } from '@containers/Home';
import { Route, Routes, ScrollRestoration } from 'react-router-dom';

const RootRouter: FC = () => {
  return (
    <>
      <ScrollRestoration
        getKey={(location, matches) => {
          // paths에 추가한 pathname은 key로 스크롤 관리
          const paths: string[] = [];
          return paths.includes(location.pathname) ? location.pathname : location.key;
        }}
      />
      <Routes>
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
};

export { RootRouter };
